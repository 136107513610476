import { createAsyncThunk } from '@reduxjs/toolkit';
import { MeasureTypeEnum } from '../../enums/feelbat.enum';
import { ServerError } from '../../types/error';
import { SensorFilter } from '../../types/feelbat';
import { handleGraphQLError } from '../../utils/errorUtils';
import { RootState } from '../store';
import {
  findAllUserSensorService,
  getMetricsBySensorIdService,
  getMetricsBySensorsIdListService,
  getSensorByIdService,
  getSensorCountByFamilyService,
  getSensorsInStockAndProjectService,
  renewSigfoxSubscriptionService,
  updateSensorService,
} from './sensors.service';

export const fetchSensorCountByFamily = createAsyncThunk<
  any,
  void, // No payload required for fetching
  { rejectValue: ServerError; state: RootState }
>('sensorCountByFamily/fetchSensorCountByFamily', async (_, thunkApi) => {
  try {
    const sensorCountByFamily = await getSensorCountByFamilyService();
    return sensorCountByFamily;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchAllUserSensor = createAsyncThunk<
  any,
  {},
  { rejectValue: ServerError; state: RootState }
>(
  'allUserSensor/fetchAllUserSensor',
  async (sensorFilter: SensorFilter = {}, thunkApi) => {
    try {
      const findAllUserSensor = await findAllUserSensorService(sensorFilter);
      return findAllUserSensor;
    } catch (err) {
      const serverError = await handleGraphQLError(err);
      return thunkApi.rejectWithValue(serverError);
    }
  }
);

export const fetchSensorsInStockAndProject = createAsyncThunk<
  any, // Replace with the expected return type
  void, // No payload required for fetching
  { rejectValue: ServerError; state: RootState }
>(
  'sensorsInStockAndProject/fetchSensorsInStockAndProject',
  async (_, thunkApi) => {
    try {
      const sensorsInStockAndProject =
        await getSensorsInStockAndProjectService();
      return sensorsInStockAndProject;
    } catch (err) {
      const serverError = await handleGraphQLError(err);
      return thunkApi.rejectWithValue(serverError);
    }
  }
);

export const updateSensor = createAsyncThunk<
  any, // Replace with the expected return type
  any, // Payload type for the update input
  { rejectValue: ServerError; state: RootState }
>('sensor/updateSensor', async (updateSensorInput, thunkApi) => {
  try {
    const updatedSensor = await updateSensorService(updateSensorInput);
    return updatedSensor;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchSensorById = createAsyncThunk<
  any,
  { id: string },
  { rejectValue: ServerError; state: RootState }
>('sensor/fetchSensorById', async ({ id }, thunkApi) => {
  try {
    const sensor = await getSensorByIdService(id);
    return sensor;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchMetricsBySensorId = createAsyncThunk<
  any,
  {
    sensorId: string;
    startRange?: string;
    endRange?: string;
    fieldType?: MeasureTypeEnum[];
  },
  { rejectValue: ServerError; state: RootState }
>(
  'metrics/fetchMetricsBySensorId',
  async ({ sensorId, startRange, endRange, fieldType }, thunkApi) => {
    try {
      const metrics = await getMetricsBySensorIdService(
        sensorId,
        startRange,
        endRange,
        fieldType
      );
      return metrics;
    } catch (err) {
      const serverError = await handleGraphQLError(err);
      return thunkApi.rejectWithValue(serverError);
    }
  }
);

export const fetchMetricsBySensorsIdList = createAsyncThunk<
  any, // Consider replacing with the actual type you expect
  {
    sensorIdList: string[];
    startRange?: string;
    endRange?: string;
    fieldType?: MeasureTypeEnum[];
  },
  { rejectValue: ServerError; state: RootState }
>('metrics/fetchMetricsBySensorsIdList', async (inputData, thunkApi) => {
  try {
    const metrics = await getMetricsBySensorsIdListService(
      inputData.sensorIdList,
      inputData.startRange,
      inputData.endRange,
      inputData.fieldType
    );
    return metrics;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

/**
 * Async thunk action to renew the SIGFOX subscription for a sensor.
 *
 * This thunk dispatches an asynchronous action to renew the SIGFOX subscription
 * for the sensor identified by the provided SIGFOX ID. It handles success and error
 * states, returning the appropriate values.
 *
 * @param {Object} inputData - The input data containing the SIGFOX ID.
 * @param {string | null | undefined} inputData.sigfoxId - The SIGFOX ID of the sensor.
 * @param {Object} thunkApi - The thunk API object.
 * @param {Function} thunkApi.rejectWithValue - Function to reject the thunk with a value.
 * @returns {Promise<boolean | ServerError>} A promise that resolves to a boolean indicating
 * the success of the subscription renewal or rejects with a ServerError object.
 * @throws Will throw an error if the SIGFOX ID is not provided or if the renewal fails.
 */
export const renewSigfoxSubscription = createAsyncThunk<
  boolean,
  { sigfoxId: string | null | undefined },
  { rejectValue: ServerError; state: RootState }
>('sensors/renewSigfoxSubscription', async (inputData, thunkApi) => {
  try {
    if (inputData.sigfoxId) {
      const renewSigfoxSubscriptionResponse =
        await renewSigfoxSubscriptionService(inputData.sigfoxId);

      if (!renewSigfoxSubscriptionResponse) {
        throw Error;
      }
      return renewSigfoxSubscriptionResponse;
    } else {
      throw Error;
    }
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});
